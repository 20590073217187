<template>
    <Header active_menu="3"/>
    <div class="">
        <div class="flexjc">
            <div class="content">
            <div class="weizhi"><span>{{ $t('您的位置：首页') }}</span><span class="dayu">&gt;</span><span class="co5">{{ $t('合作伙伴') }}</span>
            </div>
            <div class="flexJBC">
                <div>
                    <div class="lis lis_bo" v-for="(item, index) in 5" :key="index">
                        <div class="img_lis relative">
                            <img src="../assets/img/29.png" alt="" class="w100">
                            <div class="new absolute">{{ $t('新闻稿') }}</div>
                        </div>
                        <div class="fontS20 name" >该基础设施整合了社会的各种“RE” 。</div>
                        <div class="info">{{
                                $t('简单来说看风景的快乐极度分裂看到房价的客服经理时代峰峻到付件积分多少了飞机上了的看法距离开始对方就类似的飞机上来的快解封来的快放假了零售价分')
                        }}
                        </div>
                        <div class="flexJBC date">
                           <span> 05-{{index+1}}</span>
                           <span class="more cur">MORE</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="lis right_lis" v-for="(item, index) in 5" :key="index">
                        <div class="img_lis relative">
                            <img src="../assets/img/29.png" alt="" class="w100">
                            <div class="new absolute">{{ $t('新闻稿') }}</div>
                        </div>
                        <div class="fontS20 name" >该基础设施整合了社会的各种“RE” 。</div>
                        <div class="info">{{
                                $t('简单来说看风景的快乐极度分裂看到房价的客服经理时代峰峻到付件积分多少了飞机上了的看法距离开始对方就类似的飞机上来的快解封来的快放假了零售价分')
                        }}
                        </div>
                        <div class="flexJBC date">
                           <span> 05-{{index+3}}</span>
                           <span class="more cur">MORE</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="COPY">COPYRIGHT(@)2022mo005_19560-{{$t('企业集团类官网')}}</div>
        </div>
        </div>

    </div>




</template>   

<script>
// import { Swiper, SwiperSlide } from "swiper/vue";
// import { toRaw } from '@vue/reactivity'
import Header from '../components/Header2.vue'
import { reactive, toRefs } from "vue";


export default {
    name: 'Home',

    setup() {
        let active = reactive({

        })

        return {
            ...toRefs(active),
        };
    },
    components: {
        Header,
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped lang="less">
.more{
    display: none;
    text-decoration:underline;
    font-size: 14px;
}
.name{
    margin:40px 0 20px;color: #333333;
}
.right_lis {
    padding: 60px 0 60px 65px !important;
    /* border-right: 1px solid rgba(0, 0, 0, 0.1216); */
    border-bottom: 1px solid rgba(0, 0, 0, 0.1216);

}

.lis:hover {

    .date,.name{
        color: #5790CB !important;
        
    }
    .more{
        display: block;
    }
}

.date {
    font-size: 52px;
    font-weight: 800;
    /* color: #5790CB; */

    color: #E5E5E6;
    margin-top: 47px;
}

.info {
    font-size: 14px;
    line-height: 27px;
    color: #666666;
}

.new {
    padding: 0 24px;
    height: 33px;
    line-height: 33px;
    text-align: center;
    background: rgb(23, 60, 118);

    width: 42px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    color: #FFFFFF;
    bottom: 0;
    right: 0;

}

.img_lis {
    width: 560px;
    height: 202px;
}

.content {
    max-width: 1240px;
    margin: 200px auto 0;
}

.lis {
    /* max-width: 50%; */
    padding: 60px 60px 60px 0;
    box-sizing: border-box;

}

.lis_bo {
    border-right: 1px solid rgba(0, 0, 0, 0.1216);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1216);
}
@media screen and (max-width:1000px) {
    .content{
        margin-top: 80px;
        margin-left: 10vw;
    }
    .weizhi{
        font-size: 6px;
    }
    .lis_bo{
        padding: 0 10px 0 0!important;
    }
    .img_lis{
        width: 40vw;
        height: 14vw;
        padding: 5px 0 0 0 !important;
        // border: 1px solid red;
    }
    .right_lis{
        padding: 0 0 0 5px !important;
    }
    .new{
        padding: 2px;
        width: 40px;
        height: 15px;
        line-height: 15px;
        font-size: 6px;
        bottom: 0;
    }
    .fontS20{
        font-size: 8px;
        margin: 4px 0;
    }
    .info{
        font-size: 6px;
        line-height: 8px;
    }
    .date{
        font-size: 10px;
        margin-top: 5px;
    }
}
</style>
